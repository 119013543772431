export default function Informations() {
  return (
    <div className="informations">
      <a
        href="https://wa.me/5521986841208"
        target="_blank" rel="noreferrer"
        className="whatsapp"
      >
        <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
        <span>(21) 98684-1208</span>
      </a>
      <a href="https://maps.app.goo.gl/C3dbqNhCqiEoEhpM7" target="_blank" rel="noreferrer">
        <img src="./assets/icon-map.svg" alt="Endereço" />
        <span>Estrada da Matriz, 320 - Loja 103 - Pedra de Guaratiba - Rio de Janeiro</span>
      </a>
    </div>
  );
}
