import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from './Layout';
import Home from './routes/Home';
import Schedules from './routes/Schedules';
import Error from './routes/Error';
import useScrollToAnchor from './hooks/scroll-to-anchor.hook';

import 'normalize.css';
import './App.css';
import Article from './routes/Article';

export default function App() {
  useScrollToAnchor();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/agendar" element={<Schedules />} />
        <Route path="/artigo/:articleName" element={<Article />} />
        <Route path="/erro" element={<Error />} />

        {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
