export type ClubListCardTypes = {
  id?: number;
  additionalText?: string;
  price: string;
  priceExceptions?: string;
  services: string[];
  name: string;
  description: string;
  link?: string;
  onClick?: () => void;
}

export default function ClubListCard({ additionalText, price, priceExceptions, services, name, description, link, onClick }: ClubListCardTypes) {
  return (
    <div className="club-list-card">
      <div className="club-list-card-header">
        <span className="club-list-card-header-prefix">Clube</span>
        <span className="club-list-card-header-title">{name}</span>
        <span className="club-list-card-header-description">{description}</span>
        <span className="club-list-card-header-price">
          {additionalText && <span className="club-list-card-header-price-description">{additionalText}</span>}
          <span className="club-list-card-header-price-currency">R$</span>
          <span className="club-list-card-header-price-value">{price}</span>
          <span className="club-list-card-header-price-period">/mês{priceExceptions && '*'}</span>
          {priceExceptions && <span className="club-list-card-header-price-exceptions">*{priceExceptions}</span>}
        </span>
      </div>
      <div className="club-list-card-content">
        <ul>
          {services.map((item) => <li key={item}>{item}</li>)}
        </ul>

        {link && <a className="club-list-card-button" href={link} rel="noreferrer">Assinar</a>}
        {onClick && <button className="club-list-card-button" onClick={onClick} rel="noreferrer">Assinar</button>}
      </div>
    </div>
  );
}
