import { Link, Outlet } from 'react-router-dom';
import isMobile from './tools/is-mobile.tool';

export default function Layout() {
  const isAllVisible = true;

  return (
    <div style={{ height: '100%' }}>
      <nav className="menu">
        {isAllVisible && (
          <Link to="/">
            <img src="./assets/logo-horizontal-320.png" alt="Clair de Lune Salão de Beleza" />
          </Link>
        )}
        <ul className="menu-list">
          {isAllVisible && (
            <>
              <li className="menu-item">
                {isMobile() ? (
                  <a href="https://www.trinks.com/clair-de-lune/">
                    Agendar
                  </a>
                ) : (
                  <Link to="/agendar">
                    Agendar
                  </Link>
                )}
              </li>
              <li className="menu-item">
                <Link to="/#clubes">
                  Clube
                </Link>
              </li>
            </>
          )}
          <li className="menu-item menu-item-icon">
            <a
              href="https://www.facebook.com/people/Clair-de-Lune/61560906628518/"
              target="_blank" rel="noreferrer"
            >
              <img src="./assets/icon-facebook.svg" alt="Facebook" />
            </a>
          </li>
          <li className="menu-item menu-item-icon">
            <a
              href="https://instagram.com/salaoclairdelune"
              target="_blank" rel="noreferrer"
            >
              <img src="./assets/icon-instagram.svg" alt="Instagram" />
            </a>
          </li>
          <li className="menu-item menu-item-icon">
            <a
              href="https://wa.me/5521986841208"
              target="_blank" rel="noreferrer"
            >
              <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
            </a>
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}
