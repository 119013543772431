import { useEffect, useRef } from "react";

export type ModalButton = {
  id: number;
  text: string;
  action: () => void;
}

type ModalProps = {
  text: string;
  buttons?: ModalButton[];
  onClose: () => void;
}

export default function Modal({ text, buttons, onClose }: ModalProps): JSX.Element {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRef.current?.focus();
  }, [])

  return (
    <div ref={modalRef} className="modal-background requires-no-scroll">
      <div className="modal-container">
        <button className="modal-close" onClick={onClose}>X</button>
        <p className="modal-text">{text}</p>
        {buttons?.map((button) => (
          <button key={button.id} className="modal-button" onClick={button.action}>{button.text}</button>
        ))}
      </div>
    </div>
  );
}
